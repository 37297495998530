import React, { useEffect } from "react";
import { useMedia } from "use-media";
import Layout from "../components/chat-channel/Layout";
import Container from "../components/chat-channel/Container";
import TitleAndMetaTags from "../components/common/TitleAndHeader";
import RegisterSection from "../components/chat-channel/ChannelHeader";
import { RequestForm } from "../components/form";
import * as $ from "jquery";
import Faq from "../components/chat-channel/Faq";
import loadScript from "../react/loadscript";
import { CookiesPoup } from "./homepage";

const topImage = require("../assets/images/partner_img.png");

export default function PartnerHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });
  useEffect(() => {
    loadScript("/partnerForm.js")
      .then((script) => {
        // console.log("load scropt success");
        console.log(script);
      })
      .catch((err) => {
        console.log("load scropt error");
        console.error(err.message);
      });
  }, []);
  return (
    <>
      <TitleAndMetaTags
        title="Partner with Workativ | Solution Partners, Technology Partners for Conversational AI and Automation SaaS"
        description="Workativ strategic technology partnerships. Partner with Workativ. Our partnership with industry leaders can help address a wide market requirement."
        keywords={[
          "IT Helpdesk Chatbot",
          "Workativ partner",
          "Service Desk Chatbot",
        ]}
        ogImage={topImage}
        ogTitle="Partner with Workativ | Solution Partners, Technology Partners for Conversational AI and Automation SaaS"
        ogDescription="Workativ strategic technology partnerships. Partner with Workativ. Our partnership with industry leaders can help address a wide market requirement."
      />
      <Container>
        <Layout logoFor="WORKATIV" product="WORKATIV">
          <>
            <div>
              {/* Bring AI Start */}
              <section className="main-slider partner_slider">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                      <div className="main-slider-left">
                        <h1>Partner Program</h1>
                        <p>
                          Workativ offers an inclusive partner model for mutual
                          enablement as we consider our partners a vital
                          ingredient for joint success. Join the Workativ
                          program to provide a competitive advantage to
                          customers.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                      <div className="main-slider-right">
                        <img src={topImage} alt="partner_img" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="cogai_helpdesk" id="partner">
                <div className="container">
                  <h3>Types of Partners</h3>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div className="cogai_helpdesk_ai">
                        <div className="row">
                          <div className="col-lg-3 col-md-12  col-xs-12 cogai_helpdesk_ai_left">
                            <img
                              src={require("../assets/images/timeline-11.png")}
                              alt="Solution Partners"
                            />
                          </div>
                          <div className="col-lg-9 col-md-12 col-xs-12 cogai_helpdesk_ai_right">
                            <h3>Solution Partners</h3>
                            <p>
                              Leverage your market experience, customer
                              relationship, sales, and technical expertise to
                              consult, resell, and integrate Workativ Assistant.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div className="cogai_helpdesk_ai">
                        <div className="row">
                          <div className="col-lg-3 col-md-12  col-xs-12 cogai_helpdesk_ai_left">
                            <img
                              src={require("../assets/images/timeline-08.png")}
                              alt="Technology Partners"
                            />
                          </div>
                          <div className="col-lg-9 col-md-12 col-xs-12 cogai_helpdesk_ai_right">
                            <h3>Technology Partners</h3>
                            <p>
                              Integrate your product or build vertical-specific
                              ‘use cases’ to promote a joint value proposition
                              for the target industry or market.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* Bring AI Start */}
              {/* Benefits Start*/}
              <section className="suprcharging_benefits" id="numeric">
                <div className="container">
                  <div className="row">
                    {/*How it Works Content*/}
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 partner_step">
                      <div className="suprcharging_benefits_start">
                        <div className="suprcharging_benefits_start_left">
                          <h4>Partner Benefits</h4>
                          <img
                            src={require("../assets/images/partner_step.png")}
                            alt="Partner benifits"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                      <div className="benefits-workplace-content cogni_workspace_content">
                        <img
                          src={require("../assets/images/benefits_1.png")}
                          alt="Partner benifits Revenue"
                        />
                        <h3>Revenue</h3>
                        <p>
                          Grow revenue by reselling, consulting, project
                          customizations, and training.
                        </p>
                      </div>
                    </div>
                    {/*How it Works Content*/}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                      <div className="benefits-workplace-content cogni_workspace_content">
                        <img
                          src={require("../assets/images/benefits_2.png")}
                          alt="Go to Market"
                        />
                        <h3>Go to Market</h3>
                        <p>
                          Joint marketing and sales opportunities for selected
                          campaigns and events.
                        </p>
                      </div>
                    </div>
                    {/*How it Works Content*/}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                      <div className="benefits-workplace-content cogni_workspace_content">
                        <img
                          src={require("../assets/images/benefits_3.png")}
                          alt="Attractive Incentives"
                        />
                        <h3>Attractive Incentives</h3>
                        <p>
                          Incentives and rewards based on transaction volumes
                          and size.
                        </p>
                      </div>
                    </div>
                    {/*How it Works Content*/}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                      <div className="benefits-workplace-content cogni_workspace_content">
                        <img
                          src={require("../assets/images/benefits_4.png")}
                          alt="Skills and Training"
                        />
                        <h3>Skills and Training</h3>
                        <p>
                          Continuous enablement, hands-on training, access to
                          SME, and where required, privy to new software
                          developments and interaction with the Workativ
                          development team.
                        </p>
                      </div>
                    </div>
                    {/*How it Works Content*/}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                      <div className="benefits-workplace-content cogni_workspace_content">
                        <img
                          src={require("../assets/images/benefits_5.png")}
                          alt="Reduce MTTR"
                        />
                        <h3>Innovation Circle</h3>
                        <p>
                          Learn about new releases, contribute to product
                          feedback and roadmap, and help shape market
                          opportunities with both product and leadership teams.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* Benefits End*/}
              {/* Become Our Partner start */}
              <section className="become_our_partner" id="partner_scroll">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-lg-7 col-sm-12 col-xs-12">
                      <h2>
                        Workativ strategic technology partnerships that can
                        boost your solution value
                      </h2>
                      <p>
                        Workativ works with strategic partners to offer an
                        integrated value to our market. Our partnership with
                        industry leaders can help address a wide market
                        requirement. Take advantage of the big names worldwide
                        to differentiate yourself.
                      </p>
                    </div>
                    <div className="col-md-12 col-lg-5 col-sm-12 col-xs-12 patrner_forms">
                      <div class="sib-form  p-0" style="">
                        <div id="sib-form-container" class="sib-form-container">
                          <div
                            id="error-message"
                            class="sib-form-message-panel"
                            style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;max-width:540px;'
                          >
                            <div class="sib-form-message-panel__text sib-form-message-panel__text--center">
                              <svg
                                viewBox="0 0 512 512"
                                class="sib-icon sib-notification__icon"
                              >
                                <path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z" />
                              </svg>
                              <span class="sib-form-message-panel__inner-text">
                                Your request could not be saved. Please try
                                again.
                              </span>
                            </div>
                          </div>
                          <div></div>
                          <div
                            id="success-message"
                            class="sib-form-message-panel"
                            style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#085229; background-color:#e7faf0; border-radius:3px; border-color:#13ce66;max-width:540px;'
                          >
                            <div class="sib-form-message-panel__text sib-form-message-panel__text--center">
                              <svg
                                viewBox="0 0 512 512"
                                class="sib-icon sib-notification__icon"
                              >
                                <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z" />
                              </svg>
                              <span class="sib-form-message-panel__inner-text">
                                Thank you for registering our team will contact
                                you.
                              </span>
                            </div>
                          </div>
                          <div></div>
                          <div
                            id="sib-container"
                            class="sib-container--large sib-container--vertical"
                            style="text-align:center; direction:ltr"
                          >
                            <form
                              id="sib-form"
                              method="POST"
                              action="https://547c95d4.sibforms.com/serve/MUIFAFox63xt2O8LSfqBD4BZBvlv02V20GclCarb8ujQ-PRM8E6410N_r4Tl12cXqLo32F_fAWBFjbt_4nS_LH3tZ-G5nY6kGmqJe_3XHPa6eDLFWq6AZyGiYtmP_x9S3tcvdryHqpgaUgVS5VPbBN87NBrCykznp5pEH9X96pnnF0lWH4aHW1wMlSSyZhVvX9cqRCb9oc-_NWpv"
                              data-type="subscription"
                            >
                              <div
                                className={`${!isSmall ? "d-flex gap-20" : ""}`}
                              >
                                <div style="padding: 8px 0;" className="flex-1">
                                  <div class="sib-input sib-form-block pl-0 pr-0">
                                    <div class="form__entry entry_block">
                                      <div class="form__label-row ">
                                        <label
                                          class="entry__label"
                                          style='font-weight: 700; text-align:left; font-size:16px; text-align:left; font-weight:700; font-family:"Helvetica", sans-serif; color:#3c4858;'
                                          for="FIRSTNAME"
                                          data-required="*"
                                        >
                                          First Name
                                        </label>

                                        <div class="entry__field">
                                          <input
                                            class="input "
                                            maxlength="200"
                                            type="text"
                                            id="FIRSTNAME"
                                            name="FIRSTNAME"
                                            autocomplete="off"
                                            placeholder="Type your first name"
                                            data-required="true"
                                            required
                                            style={`${
                                              isSmall
                                                ? "height:fit-content;"
                                                : ""
                                            }`}
                                          />
                                        </div>
                                      </div>

                                      <label
                                        class="entry__error entry__error--primary"
                                        style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;'
                                      ></label>
                                    </div>
                                  </div>
                                </div>
                                <div style="padding: 8px 0;" className="flex-1">
                                  <div class="sib-input sib-form-block pl-0 pr-0">
                                    <div class="form__entry entry_block">
                                      <div class="form__label-row ">
                                        <label
                                          class="entry__label"
                                          style='font-weight: 700; text-align:left; font-size:16px; text-align:left; font-weight:700; font-family:"Helvetica", sans-serif; color:#3c4858;'
                                          for="LASTNAME"
                                          data-required="*"
                                        >
                                          Last Name
                                        </label>

                                        <div class="entry__field">
                                          <input
                                            class="input "
                                            maxlength="200"
                                            type="text"
                                            id="LASTNAME"
                                            name="LASTNAME"
                                            autocomplete="off"
                                            placeholder="Type your last name"
                                            data-required="true"
                                            required
                                            style={`${
                                              isSmall
                                                ? "height:fit-content;"
                                                : ""
                                            }`}
                                          />
                                        </div>
                                      </div>

                                      <label
                                        class="entry__error entry__error--primary"
                                        style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;'
                                      ></label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${!isSmall ? "d-flex gap-20" : ""}`}
                              >
                                <div style="padding: 8px 0;" className="flex-1">
                                  <div class="sib-input sib-form-block pl-0 pr-0">
                                    <div class="form__entry entry_block">
                                      <div class="form__label-row ">
                                        <label
                                          class="entry__label"
                                          style='font-weight: 700; text-align:left; font-size:16px; text-align:left; font-weight:700; font-family:"Helvetica", sans-serif; color:#3c4858;'
                                          for="EMAIL"
                                          data-required="*"
                                        >
                                          Email
                                        </label>

                                        <div class="entry__field">
                                          <input
                                            class="input "
                                            type="text"
                                            id="EMAIL"
                                            name="EMAIL"
                                            autocomplete="off"
                                            placeholder="Type your email"
                                            data-required="true"
                                            required
                                            style={`${
                                              isSmall
                                                ? "height:fit-content;"
                                                : ""
                                            }`}
                                          />
                                        </div>
                                      </div>

                                      <label
                                        class="entry__error entry__error--primary"
                                        style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;'
                                      ></label>
                                    </div>
                                  </div>
                                </div>
                                <div style="padding: 8px 0;" className="flex-1">
                                  <div class="sib-input sib-form-block pl-0 pr-0">
                                    <div class="form__entry entry_block">
                                      <div class="form__label-row ">
                                        <label
                                          class="entry__label"
                                          style='font-weight: 700; text-align:left; font-size:16px; text-align:left; font-weight:700; font-family:"Helvetica", sans-serif; color:#3c4858;'
                                          for="COMPANY_NAME"
                                          data-required="*"
                                        >
                                          Company
                                        </label>

                                        <div class="entry__field">
                                          <input
                                            class="input "
                                            maxlength="200"
                                            type="text"
                                            id="COMPANY_NAME"
                                            name="COMPANY_NAME"
                                            autocomplete="off"
                                            placeholder="Type your company name"
                                            data-required="true"
                                            required
                                            style={`${
                                              isSmall
                                                ? "height:fit-content;"
                                                : ""
                                            }`}
                                          />
                                        </div>
                                      </div>

                                      <label
                                        class="entry__error entry__error--primary"
                                        style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;'
                                      ></label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${!isSmall ? "d-flex gap-20" : ""}`}
                              >
                                <div style="padding: 8px 0;" className="flex-1">
                                  <div class="sib-input sib-form-block pl-0 pr-0">
                                    <div class="form__entry entry_block">
                                      <div class="form__label-row ">
                                        <label
                                          class="entry__label"
                                          style='font-weight: 700; text-align:left; font-size:16px; text-align:left; font-weight:700; font-family:"Helvetica", sans-serif; color:#3c4858;'
                                          for="MOBILE_NUMBER"
                                          data-required="*"
                                        >
                                          Mobile number
                                        </label>
                                        <div class="entry__field">
                                          <input
                                            maxlength="200"
                                            type="text"
                                            data-numeric="true"
                                            class="input "
                                            id="MOBILE_NUMBER"
                                            name="MOBILE_NUMBER"
                                            autocomplete="off"
                                            placeholder="Type your mobile number"
                                            data-required="true"
                                            required
                                            style={`${
                                              isSmall
                                                ? "height:fit-content;"
                                                : ""
                                            }`}
                                          />
                                        </div>
                                      </div>
                                      <label
                                        class="entry__error entry__error--primary"
                                        style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;'
                                      ></label>
                                    </div>
                                  </div>
                                </div>
                                <div style="padding: 8px 0;" className="flex-1">
                                  <div class="sib-input sib-form-block pl-0 pr-0">
                                    <div class="form__entry entry_block">
                                      <div class="form__label-row ">
                                        <label
                                          class="entry__label"
                                          style='font-weight: 700; text-align:left; font-size:16px; text-align:left; font-weight:700; font-family:"Helvetica", sans-serif; color:#3c4858;'
                                          for="WEBSITE"
                                        >
                                          Website
                                        </label>

                                        <div class="entry__field">
                                          <input
                                            class="input "
                                            maxlength="200"
                                            type="text"
                                            id="WEBSITE"
                                            name="WEBSITE"
                                            autocomplete="off"
                                            placeholder="Entre your website"
                                            style={`${
                                              isSmall
                                                ? "height:fit-content;"
                                                : ""
                                            }`}
                                          />
                                        </div>
                                      </div>

                                      <label
                                        class="entry__error entry__error--primary"
                                        style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;'
                                      ></label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${!isSmall ? "d-flex gap-20" : ""}`}
                              >
                                <div style="padding: 8px 0;" className="flex-1">
                                  <div class="sib-input sib-form-block pl-0 pr-0">
                                    <div class="form__entry entry_block">
                                      <div class="form__label-row ">
                                        <label
                                          class="entry__label"
                                          style='font-weight: 700; text-align:left; font-size:16px; text-align:left; font-weight:700; font-family:"Helvetica", sans-serif; color:#3c4858;'
                                          for="COUNTRY"
                                          data-required="*"
                                        >
                                          Country
                                        </label>

                                        <div class="entry__field">
                                          <input
                                            class="input "
                                            maxlength="200"
                                            type="text"
                                            id="COUNTRY"
                                            name="COUNTRY"
                                            autocomplete="off"
                                            placeholder="COUNTRY"
                                            data-required="true"
                                            required
                                            style={`${
                                              isSmall
                                                ? "height:fit-content;"
                                                : ""
                                            }`}
                                          />
                                        </div>
                                      </div>

                                      <label
                                        class="entry__error entry__error--primary"
                                        style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;'
                                      ></label>
                                    </div>
                                  </div>
                                </div>
                                <div style="padding: 8px 0;" className="flex-1">
                                  <div
                                    class="sib-select sib-form-block pl-0 pr-0"
                                    data-required="true"
                                  >
                                    <div class="form__entry entry_block">
                                      <div class="form__label-row ">
                                        <label
                                          class="entry__label"
                                          style='font-weight: 700; text-align:left; font-size:16px; text-align:left; font-weight:700; font-family:"Helvetica", sans-serif; color:#3c4858;'
                                          for="PARTNER_TYPE"
                                          data-required="*"
                                        >
                                          Select a partner type
                                        </label>
                                        <div class="entry__field">
                                          <select
                                            class="input"
                                            id="PARTNER_TYPE"
                                            name="PARTNER_TYPE"
                                            data-required="true"
                                            style={`${
                                              isSmall
                                                ? "height:fit-content;"
                                                : ""
                                            }`}
                                          >
                                            <option
                                              value=""
                                              disabled
                                              selected
                                              hidden
                                            >
                                              Select one
                                            </option>

                                            <option
                                              class="sib-menu__item"
                                              value="1"
                                            >
                                              Technology partner
                                            </option>
                                            <option
                                              class="sib-menu__item"
                                              value="2"
                                            >
                                              Solutions partner
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <label
                                        class="entry__error entry__error--primary"
                                        style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;'
                                      ></label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style="padding: 8px 0;" className="flex-1">
                                <div
                                  class="sib-form-block pr-0 pl-0"
                                  style="text-align: left"
                                >
                                  <button
                                    class="sib-form-block__button sib-form-block__button-with-loader"
                                    style='font-size:16px; text-align:left; font-weight:700; font-family:"Helvetica", sans-serif; color:#FFFFFF; background-color:#2468f6; border-radius:30px; border:1px solid #2468f6; padding: 8px 55px; width: fit-content;'
                                    form="sib-form"
                                    type="submit"
                                  >
                                    <svg
                                      class="icon clickable__icon progress-indicator__icon sib-hide-loader-icon"
                                      viewBox="0 0 512 512"
                                    >
                                      <path d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z" />
                                    </svg>
                                    Submit
                                  </button>
                                </div>
                              </div>

                              <input
                                type="text"
                                name="email_address_check"
                                value=""
                                class="input--hidden"
                              />
                              <input type="hidden" name="locale" value="en" />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* Become Our Partner End */}
            </div>
            {/* <RequestForm isFooterForm={true} /> */}
            <Faq />
            {/* <CookiesPoup /> */}
          </>
        </Layout>
      </Container>
    </>
  );
}
