import React, { useEffect, useState, useRef } from "react";
import { UpArrowIcon } from "../common/Icons";
export const faqData = [
  {
    header: "What does the free trial entail?",
    paragraph:
      "  We give you a workspace for you to experience all the features in Workativ Assistant, from A-Z. You can build, automate, and deploy the digital assistant with ease, without any coding!"
  },
  {
    header: " What do I need to sign up for a free trial?",
    paragraph:
      " We require the necessary information about you and your company to ensure your interest is genuine, and we can offer you our best assistance."
  },
  {
    header: " What happens when I sign up for a trial?",
    paragraph:
      " You will receive an email from us, acknowledging your request. Our team will evaluate your requirements and get back to you in 48 hours to confirm your interest in going ahead with the trial to enable us to manage our resources to support you through the trial period."
  },
  {
    header: "What happens after my free trial expires?",
    paragraph:
      " After the trial period expires, your account will be disabled. If you do not subscribe to a plan at the end of the trial, you can contact us to reactivate your Workativ account when you are ready to subscribe (within one month after trial expiry)."
  },
  {
    header: " Will there be any support during the trial period?",
    paragraph:
      "    Yes. We have our experts to help you onboard and work on the product, handholding you through the initial phase until you are ready to work on the product by yourself. We will provide support during the trial period and will be most happy to answer any questions you have to ensure that our product meets your objectives."
  },
  {
    header: " Do you have a cap on the number of users?",
    paragraph:
      " From each organization, only one user would be allowed to work on the application as an admin. There is no limit to the number of employees using Workativ Assistant."
  },
  {
    header: "How do I contact you",

    paragraph:
      " Please send your questions or emails to sales@workativ.com   We will respond to all your emails at the earliest."
  }
];

const Accordion = ({ title, children }) => {
  const [isOpen, setOpen] = React.useState(false);
  return (
    <div className="accordion-wrapper">
      <li
        className={`link ${isOpen ? "active" : ""}`}
        onClick={() => setOpen(!isOpen)}
      >
        {title}
        <UpArrowIcon />
      </li>

      <ul className={`accordion-item submenu ${!isOpen ? "collapsed" : ""}`}>
        <div className="accordion-content submenu_div">
          <li> {children}</li>
        </div>
      </ul>
    </div>
  );
};
export default function Faq() {
  return (
    <div className="wrapper">
      <section className="accordian_landing trial_accordian_landing">
        <div className="container">
          <h2>FAQ</h2>
          <div className="col-12 pl-0 faq_landing">
            <ul id="accordion" className="accordion pl-0">
              {faqData.map(faq => (
                <Accordion title={faq.header}>{faq.paragraph}</Accordion>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}
